<script lang="ts">
	import { gdprPathMap } from "../../../../core/schema/paths/gdprPathMap";
	import { getGlobalSettings } from "../../getGlobalSettings";
	import { getTranslate } from "../../utils/getTranslate";
	import Checkbox from "./Checkbox.svelte";
	import translations from "./GdprCheckbox.translations.json";

	const translate = getTranslate(translations);

	const { TENANT_LOCALE } = getGlobalSettings();

	let formControlClass = "";
	export { formControlClass as class };
	export let gdprApprovedAt: string | null = null;

	let input: HTMLInputElement | null = null;

	$: {
		if (input) {
			input.checked = Boolean(gdprApprovedAt);
		}
	}

	function checkedTerms(event: Event): void {
		const input = event.target as HTMLInputElement;
		gdprApprovedAt = input.checked ? new Date().toISOString() : null;
	}
</script>

<div class="relative">
	<Checkbox
		bind:input
		id="gdprApproved"
		name=""
		on:change={checkedTerms}
		required
		class={formControlClass}
		labelProps={{ class: "!text-xs" }}
	>
		{translate("agree")}&nbsp;<a
			class="text-black-400 hover:text-black-700 decoration-primary-400 font-medium underline transition-[color,text-decoration] duration-500 ease-in-out hover:decoration-transparent"
			href="/{gdprPathMap[TENANT_LOCALE]}"
			target="_blank"
		>
			{translate("policy")}
		</a>
	</Checkbox>
	<input type="hidden" name="gdprApprovedAt" value={gdprApprovedAt ?? ""} />
</div>
