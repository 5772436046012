import type { SourceSetQuery } from "./SourceSetQuery.js";
import { querySizeMappings } from "./querySizeMappings.js";

export function sortSourceSetQuery(first: SourceSetQuery, second: SourceSetQuery): number {
	const firstSize = querySizeMappings[first.slice(1) as keyof typeof querySizeMappings];
	const secondSize = querySizeMappings[second.slice(1) as keyof typeof querySizeMappings];
	const firstDirection = first.startsWith("<") ? 1 : -1;
	const secondDirection = second.startsWith("<") ? 1 : -1;

	if (firstDirection === secondDirection) {
		if (firstDirection === 1) {
			return firstSize - secondSize;
		} else {
			return secondSize - firstSize;
		}
	}

	return secondDirection - firstDirection;
}
