import type { ActionReturn } from "svelte/action";

export function handleInvalidInputs(form: HTMLFormElement): ActionReturn {
	let invalidTimeout: number | undefined;
	let scrollTimeout: number | undefined;
	const registeredInputs: (HTMLInputElement | HTMLTextAreaElement)[] = [];

	// https://stackoverflow.com/a/78227361
	function onInvalid(): void {
		// Multiple elements in the form will likely report being invalid at the same time, so we wait 50ms
		// until they've stopped triggering
		clearTimeout(invalidTimeout);
		invalidTimeout = window.setTimeout(() => {
			// Once the invalid events have stopped, we expect the browser to scroll an element into view
			// Wait until that scrolling has stopped before attempting to trigger the validation again
			let hasScrolled = false;
			function onScroll(): void {
				hasScrolled = true;
				clearTimeout(scrollTimeout);
				scrollTimeout = window.setTimeout(() => {
					// We've finished scrolling after an invalid event, so re-trigger the form validation
					form.reportValidity();
					window.removeEventListener("scroll", onScroll);
				}, 50);
			}

			window.addEventListener("scroll", onScroll);

			// No scroll happened, validate right away.
			setTimeout(() => {
				if (!hasScrolled) {
					clearTimeout(scrollTimeout);
					window.removeEventListener("scroll", onScroll);
				}
			}, 20);
		}, 50);
	}

	function getInputs(): (HTMLTextAreaElement | HTMLInputElement)[] {
		return Array.from(form.querySelectorAll<HTMLTextAreaElement | HTMLInputElement>("input, textarea"));
	}

	function listenToInvalidInputs(): void {
		const inputs = getInputs();

		for (const input of inputs) {
			if (registeredInputs.includes(input)) {
				return;
			}

			input.addEventListener("invalid", onInvalid);
			registeredInputs.push(input);
		}
	}

	listenToInvalidInputs();
	const observer = new MutationObserver((mutations) => {
		const isChildList = mutations.some((mutation) => mutation.type === "childList");
		if (!isChildList) {
			return;
		}
		listenToInvalidInputs();
	});
	observer.observe(form, { childList: true, subtree: true });

	return {
		destroy(): void {
			observer.disconnect();
		},
	};
}
