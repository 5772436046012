import type { ButtonProps, ButtonVariant } from "./ButtonProps.js";

export function generateButtonClasses({ variant, disabled }: ButtonProps): string {
	const btnVariants: Record<ButtonVariant, string> = {
		primary:
			"group bg-primaryGradient border-primary hover:text-primary focus:text-primary active:text-primary text-white bg-center focus:bg-left group-hover:text-primary active:bg-left group-hover:bg-left hover:bg-left bg-[length:350%]",
		secondary:
			"group bg-secondaryGradient border-gray text-black hover:border-white hover:text-white group-hover:text-white focus:border-white active:border-white bg-center focus:text-white active:text-white group-hover:bg-left hover:bg-left focus:bg-left active:bg-left bg-[length:350%]",
		transparent: "group text-black flex gap-2 items-center",
		primaryTransparent: "group text-primary flex gap-4 items-center",
		custom: "",
	};
	const variantClass = btnVariants[variant ?? "primary"];

	const disabledClass = disabled && "opacity-50 pointer-events-none";

	if (variant !== "transparent" && variant !== "primaryTransparent") {
		return [
			"rounded-[.65rem] flex gap-4 items-center no-underline border px-4 leading-[1.35] pt-[.875rem] pb-[.8rem] font-semibold transition-all duration-300 tracking-[1.2px] uppercase text-[0.75rem] w-fit cursor-pointer",
			variantClass,
			disabledClass,
		]
			.filter(Boolean)
			.join(" ");
	}

	return [variantClass, disabledClass].filter(Boolean).join(" ");
}
