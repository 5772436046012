<script lang="ts">
	import { parseSourceSet } from "./parseSourceSet.js";
	import type { SourceSetQuery } from "./SourceSetQuery.js";
	import { onMount } from "svelte";
	import { sortSourceSetQuery } from "./sortSourceSetQuery.js";

	let className = "";
	export { className as class };

	export let width: number;
	export let height: number;
	export let imgClass = "";
	export let loading: "eager" | "lazy" = "lazy";
	export let image: string;
	export let alt: string;
	export let sourcesets: Partial<Record<SourceSetQuery, string>> = {};
	export let naturalHeight: number | undefined = undefined;
	export let naturalWidth: number | undefined = undefined;

	$: sortedSourcesets = Object.entries(sourcesets).toSorted(([firstQuery], [secondQuery]) =>
		sortSourceSetQuery(firstQuery as SourceSetQuery, secondQuery as SourceSetQuery),
	) as [SourceSetQuery, string][];

	onMount(() => {
		window.refreshFsLightbox?.();
	});
</script>

<div class={className}>
	<picture>
		{#each sortedSourcesets as [query, image]}
			<source srcset={`${image} 1x`} type="image/webp" media={parseSourceSet(query)} />
		{/each}

		<img class={imgClass} {alt} src={image} {width} {height} {loading} bind:naturalHeight bind:naturalWidth />
	</picture>
</div>
