<script lang="ts">
	export let aria: string;
</script>

<div class="contents">
	<slot></slot>
	{#if aria}
		<!-- Google complains about some generic texts, this is a workaround, a simple aria-label is not enough -->
		<span class="pointer-events-none absolute overflow-hidden opacity-0">{aria}</span>
	{/if}
</div>
