import type { EnumObject } from "./EnumObject.js";
import type { EnumType } from "./EnumType.js";
import { values } from "./values.js";

export function isEnum<Enum extends EnumType>(enumObj: EnumObject<Enum>): (value: unknown) => value is Enum {
	const enumValues = values(enumObj);
	return function (value: unknown): value is Enum {
		return enumValues.includes(value as Enum);
	};
}
