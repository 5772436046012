import type { EnumObject } from "./EnumObject.js";
import type { EnumType } from "./EnumType.js";

/**
 * Get a list of enum values.
 */
export function keys<Enum extends EnumType>(enumObj: EnumObject<Enum>): string[] {
	return Object.keys(enumObj).filter(
		(key) => key.charCodeAt(0) < "0".charCodeAt(0) || "9".charCodeAt(0) < key.charCodeAt(0),
	);
}
