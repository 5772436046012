<script lang="ts">
	import "../assets/css/main.css";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { getContext, onMount } from "svelte";
	import { pageSlugContextKey } from "../contexts/pageSlugContextKey.js";
	import { titleContextKey } from "../contexts/titleContextKey.js";
	import { descriptionContextKey } from "../contexts/descriptionContextKey.js";
	import Footer from "./Footer.svelte";
	import MarketingFooter from "./MarketingFooter.svelte";
	import Header from "./Header.svelte";
	import HeadFavicons from "./HeadFavicons.svelte";
	import { menuContextKey } from "../contexts/menuContextKey.js";
	import GoogleAnalytics from "./GoogleAnalytics.svelte";
	import { appName } from "../../../core/schema/appName.js";
	import { scrollLockStore } from "../stores/scrollLockStore.js";
	import spaceGroteskRegular from "../../../core/assets/fonts/space-grotesk/SpaceGrotesk-Regular.woff2";
	import spaceGroteskMedium from "../../../core/assets/fonts/space-grotesk/SpaceGrotesk-Medium.woff2";
	import spaceGroteskSemibold from "../../../core/assets/fonts/space-grotesk/SpaceGrotesk-SemiBold.woff2";
	import spaceGroteskBold from "../../../core/assets/fonts/space-grotesk/SpaceGrotesk-Bold.woff2";
	import interRegular from "../../../core/assets/fonts/inter/Inter-Regular.woff2";
	import interMedium from "../../../core/assets/fonts/inter/Inter-Medium.woff2";
	import interSemibold from "../../../core/assets/fonts/inter/Inter-SemiBold.woff2";
	import interBold from "../../../core/assets/fonts/inter/Inter-Bold.woff2";
	import { createTitle } from "../utils/createTitle.js";
	import { keywordsContextKey } from "../contexts/keywordsContextKey.js";
	import { thumbnailImageContextKey } from "../contexts/thumbnailImageContextKey.js";
	import { localeLangMap } from "../../../core/schema/localeLangMap.js";
	import { tenants } from "../../../core/config/tenants.js";
	import type { GlobalTemplateProps } from "../GlobalTemplateProps.js";
	import posterImage from "../assets/images/hp-banner.jpg";

	const { ROBOTS, WEB_URL, TENANT_LOCALE, TENANT_ID } = getGlobalSettings();

	const pageSlug = getContext<GlobalTemplateProps["pageSlug"]>(pageSlugContextKey);
	const title = createTitle(getContext<GlobalTemplateProps["title"]>(titleContextKey));
	const description = getContext<GlobalTemplateProps["description"]>(descriptionContextKey); // TODO ?? defaultDescription
	const keywords = getContext<GlobalTemplateProps["keywords"]>(keywordsContextKey);
	const menu = getContext<GlobalTemplateProps["menu"]>(menuContextKey);
	const ogImage = getContext<string | undefined>(thumbnailImageContextKey) ?? posterImage;

	onMount(async () => {
		await import("../assets/js/fslightbox-pro-3.4.1/fslightbox.js");
	});

	const canonical = `${WEB_URL[TENANT_ID]}/${pageSlug[TENANT_LOCALE] ?? ""}`;
	$: {
		document.documentElement.style.overflow = $scrollLockStore ? "hidden" : "auto";
	}
</script>

<svelte:head>
	<!-- Preloads of fonts used the top pages -->
	<!-- TODO Revise... -->
	{#each [spaceGroteskRegular, spaceGroteskMedium, spaceGroteskSemibold, spaceGroteskBold, interRegular, interMedium, interSemibold, interBold] as font}
		<link rel="preload" as="font" type="font/woff2" href={font} crossorigin="anonymous" />
	{/each}

	<!-- Site name -->
	<meta property="og:site_name" content={appName} />
	<meta name="twitter:site" content={appName} />
	<meta name="application-name" content={appName} />
	<meta name="apple-mobile-web-app-title" content={appName} />

	<!-- Dynamic Open graph tags (Meta) -->
	<meta property="og:url" content={canonical} />
	{#if ogImage}
		<meta property="og:image" content={ogImage} />
	{/if}

	<!-- Dynamic Twitter Meta tags -->
	<meta name="twitter:card" content="summary_large_image" />
	<meta property="twitter:domain" content={WEB_URL[TENANT_ID]} />
	<meta property="twitter:url" content={canonical} />
	{#if ogImage}
		<meta name="twitter:image" content={ogImage} />
	{/if}

	<!-- Title, description and keywords -->
	<title>{title}</title>
	<meta property="og:title" content={title} />
	<meta property="og:title" content={title} />
	<meta name="twitter:title" content={title} />
	<meta name="description" content={description} />
	<meta name="og:description" content={description} />
	<meta name="twitter:description" content={description} />
	{#if keywords}
		<meta name="keywords" content={keywords} />
	{/if}

	<!-- Canonical and hreflang -->
	<link rel="canonical" href={canonical} />
	{#each Object.values(tenants) as tenant}
		{#if tenant.locale in pageSlug}
			<link
				rel="alternate"
				hreflang={localeLangMap[tenant.locale]}
				href="{WEB_URL[tenant.id]}/{pageSlug[tenant.locale]}"
			/>
		{/if}
	{/each}

	<!-- Robots -->
	<meta name="robots" content={`${ROBOTS}, ${ROBOTS === "noindex" ? "no" : ""}follow`} />
</svelte:head>

<HeadFavicons />

<GoogleAnalytics />

<div class="contents">
	<Header {menu} />

	<main class="grow pt-20 lg:pt-[4.625rem] xl:pt-[4.8125rem]">
		<slot />
	</main>

	<Footer />
</div>

<MarketingFooter />
