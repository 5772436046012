<script lang="ts">
	import favicon16 from "../../../core/assets/favicon-vertiflex.png?w=16&imagetools";
	import favicon32 from "../../../core/assets/favicon-vertiflex.png?w=32&imagetools";
	import favicon57 from "../../../core/assets/favicon-vertiflex.png?w=57&imagetools";
	import favicon60 from "../../../core/assets/favicon-vertiflex.png?w=60&imagetools";
	import favicon72 from "../../../core/assets/favicon-vertiflex.png?w=72&imagetools";
	import favicon76 from "../../../core/assets/favicon-vertiflex.png?w=76&imagetools";
	import favicon96 from "../../../core/assets/favicon-vertiflex.png?w=96&imagetools";
	import favicon114 from "../../../core/assets/favicon-vertiflex.png?w=114&imagetools";
	import favicon120 from "../../../core/assets/favicon-vertiflex.png?w=120&imagetools";
	import favicon144 from "../../../core/assets/favicon-vertiflex.png?w=144&imagetools";
	import favicon152 from "../../../core/assets/favicon-vertiflex.png?w=152&imagetools";
	import favicon180 from "../../../core/assets/favicon-vertiflex.png?w=180&imagetools";
	import favicon192 from "../../../core/assets/favicon-vertiflex.png?w=192&imagetools";
</script>

<svelte:head>
	<!--<link rel="icon" type="image/svg+xml" href={faviconSvg} /> TODO No svg -->
	<link rel="apple-touch-icon" sizes="57x57" href={favicon57} />
	<link rel="apple-touch-icon" sizes="60x60" href={favicon60} />
	<link rel="apple-touch-icon" sizes="72x72" href={favicon72} />
	<link rel="apple-touch-icon" sizes="76x76" href={favicon76} />
	<link rel="apple-touch-icon" sizes="114x114" href={favicon114} />
	<link rel="apple-touch-icon" sizes="120x120" href={favicon120} />
	<link rel="apple-touch-icon" sizes="144x144" href={favicon144} />
	<link rel="apple-touch-icon" sizes="152x152" href={favicon152} />
	<link rel="apple-touch-icon" sizes="180x180" href={favicon180} />
	<link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
	<link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
	<link rel="icon" type="image/png" sizes="96x96" href={favicon96} />
	<link rel="icon" type="image/png" sizes="192x192" href={favicon192} />
	<meta name="msapplication-TileImage" content={favicon144} />
</svelte:head>
