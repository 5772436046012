<script lang="ts">
	import type { MenuItem } from "../../../../core/schema/MenuItem";
	import Menu from "./Menu.svelte";

	export let menu: MenuItem[];
</script>

<nav class="hidden w-fit lg:block">
	<Menu {menu} />
</nav>
