<script lang="ts">
	import Icon from "./Icon.svelte";
	import cross from "../../../core/assets/icons/cross.svg?raw";
	import { scrollLockStore } from "../stores/scrollLockStore.js";

	let dialogClass = "";
	export { dialogClass as class };
	export let show = false;

	let dialog: HTMLDialogElement | null = null;
	$: {
		if (dialog) {
			if (show && !dialog.open) {
				dialog.showModal();
			}
			if (!show && dialog.open) {
				dialog.close();
			}
		}
	}

	$: {
		if (show && !$scrollLockStore) {
			scrollLockStore.set(true);
		}
	}
	$: {
		if (!show) {
			scrollLockStore.set(show);
		}
	}

	function onClose(): void {
		show = false;
	}
</script>

<dialog
	bind:this={dialog}
	class="m-auto bg-transparent backdrop:fixed backdrop:inset-0 backdrop:backdrop-blur-sm"
	on:close={onClose}
>
	<div class="m-2 rounded-lg bg-white p-6 {dialogClass}">
		<div class="mb-4 flex items-start gap-2 {$$slots.title ? 'justify-between' : 'justify-end'}">
			{#if $$slots.title}
				<slot name="title" />
			{/if}
			<button class="group p-1" type="button" on:click={onClose}>
				<Icon icon={cross} class="group-hover:text-primary aspect-square h-4 transition-colors duration-300" />
			</button>
		</div>
		<div class="text-left">
			<slot />
		</div>
		{#if $$slots.actions}
			<div class="mt-8">
				<slot name="actions" />
			</div>
		{/if}
	</div>
</dialog>
