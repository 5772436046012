<script lang="ts">
	import { isMenuActive } from "../../utils/isMenuActive.js";
	import Link from "../Link.svelte";
	import { createEventDispatcher, getContext } from "svelte";
	import type { MenuItem } from "../../../../core/schema/MenuItem.js";
	import { pageSlugContextKey } from "../../contexts/pageSlugContextKey.js";
	import arrowDown from "../../../../core/assets/icons/arrow-down.svg?raw";
	import Button from "../Button.svelte";
	import Submenu from "./Submenu.svelte";
	import { writable } from "svelte/store";
	import { getGlobalSettings } from "../../getGlobalSettings.js";
	import type { GlobalTemplateProps } from "../../GlobalTemplateProps.js";
	import { nbspify } from "../../../../core/utils/nbspify.js";
	import { getTranslate } from "../../utils/getTranslate.js";
	import translations from "./Menu.translations.json";

	export let menu: MenuItem[];

	const translate = getTranslate(translations);
	const { TENANT_LOCALE } = getGlobalSettings();
	const pageSlug = getContext<GlobalTemplateProps["pageSlug"]>(pageSlugContextKey);

	const dispatch = createEventDispatcher<{
		click: MenuItem;
	}>();

	const activeSubmenu = writable<MenuItem["id"] | null>(null);

	function toggleSubmenu(event: MouseEvent, id: string): void {
		event.preventDefault();
		if ($activeSubmenu === null) {
			activeSubmenu.set(id);
		} else {
			activeSubmenu.set(null);
		}
	}

	// eslint-disable-next-line no-warning-comments
	// FIXME remove this after upgrade to Svelte 5.
	/* eslint-disable @typescript-eslint/explicit-function-return-type */
</script>

<div class="mt-8 flex justify-center max-lg:overflow-y-auto max-lg:overflow-x-hidden lg:mt-0 lg:h-full lg:w-auto">
	<ul class="flex w-full flex-col items-center gap-1 sm:gap-2 lg:flex-row lg:justify-center lg:gap-1 xl:gap-4">
		{#each menu as menuItem}
			<li class="group/menu w-full lg:w-auto lg:py-3">
				<div class="group/menu relative w-full lg:w-fit">
					<div class="group/menu relative w-full">
						<Link
							noUnderline
							class="text-secondary lg:text-3xs group-hover/menu:text-primary !block w-full px-5 py-5 text-center text-xs font-medium uppercase tracking-[0.125rem] transition-colors duration-300 lg:w-fit lg:whitespace-nowrap lg:rounded-lg lg:px-3 lg:py-3 group-hover/menu:lg:bg-white {menuItem
								.submenus.length > 0
								? '!pr-7'
								: ''} {isMenuActive(menuItem, pageSlug[TENANT_LOCALE] ?? '') ? '!text-primary' : ''}"
							href={`/${menuItem.path}`}
							target={menuItem.target}
							on:click={() => {
								dispatch("click", menuItem);
							}}
						>
							{nbspify(menuItem.name)}
						</Link>
						{#if menuItem.submenus.length > 0}
							<Button
								aria-label={translate("toggleSubmenu")}
								class="group-hover/menu:text-primary absolute bottom-0 right-0 top-0 h-full justify-center px-5 py-3 transition-all duration-300 ease-in-out lg:bottom-auto lg:right-2 lg:top-1/2 lg:h-fit lg:-translate-y-1/2 lg:px-0 group-hover/menu:lg:rotate-180 {isMenuActive(
									menuItem,
									pageSlug[TENANT_LOCALE] ?? '',
								)
									? '!text-primary'
									: ''}"
								variant="transparent"
								icon={arrowDown}
								iconClass="w-4"
								on:click={(event) => {
									toggleSubmenu(event, menuItem.id);
								}}
							></Button>
						{/if}
					</div>

					{#if menuItem.submenus.length > 0}
						<Submenu {activeSubmenu} {menuItem} />
					{/if}
				</div>
			</li>
		{/each}
	</ul>
</div>
