import { type Readable, type Writable, get, writable } from "svelte/store";

type PromiseStoreState<Result, Variables extends unknown[]> =
	| {
			status: "initial";
	  }
	| { status: "loading"; promise: Promise<Result>; variables: Variables }
	| { status: "success"; result: Result }
	| { status: "error"; error: unknown };

export type PromiseStore<Result, Variables extends unknown[]> = Readable<PromiseStoreState<Result, Variables>> & {
	call(...variables: Variables): Promise<Result>;
};

async function setupPromise<Result, Variables extends unknown[]>(
	store: Writable<PromiseStoreState<Result, Variables>>,
	callback: (...variables: Variables) => Promise<Result>,
	variables: Variables,
	onSuccess?: (result: Result) => void,
	onError?: (error: unknown) => void,
): Promise<Result> {
	const promise = callback(...variables);
	store.set({ status: "loading", promise, variables });
	return promise
		.then((result) => {
			const current = get(store);
			if (current.status === "loading" && promise === current.promise) {
				store.set({ status: "success", result });
				onSuccess?.(result);
			}
			return result;
		})
		.catch((error: unknown) => {
			const current = get(store);
			if (current.status === "loading" && promise === current.promise) {
				store.set({ status: "error", error });
				onError?.(error);
			}
			throw error;
		});
}

export function createPromiseStore<Result, Variables extends unknown[]>(
	callback: (...variables: Variables) => Promise<Result>,
	onSuccess?: (result: Result) => void,
	onError?: (error: unknown) => void,
): PromiseStore<Result, Variables> {
	const store = writable<PromiseStoreState<Result, Variables>>({ status: "initial" });
	const { subscribe } = store;

	return {
		call: async (...variables: Variables): Promise<Result> => {
			return setupPromise(store, callback, variables, onSuccess, onError);
		},
		subscribe,
	};
}
