import type { DocumentNode, FieldDefinitionNode } from "graphql";
import { Kind, getOperationAST, print } from "graphql";
import type { ExecutionResult } from "graphql/execution/execute.js";
import type { Empty } from "../../../core/utils/Empty.js";
import type { ListResponse } from "../../../core/utils/aws/dynamodb/ListResponse.js";

async function request<Response, Variables extends Record<string, unknown> = Empty>(
	endpoint: string,
	document: DocumentNode,
	variables?: Variables,
): Promise<Response> {
	const operationDefinion = getOperationAST(document);
	if (!operationDefinion) {
		throw new Error("Couldn't find operation definition in GraphQL query");
	}
	const fieldSelection = operationDefinion.selectionSet.selections.find(({ kind }) => kind === Kind.FIELD) as
		| FieldDefinitionNode
		| undefined;
	if (!fieldSelection) {
		throw new Error("Couldn't find field selection in GraphQL query");
	}
	const queryName = fieldSelection.name.value;
	const response = await fetch(endpoint, {
		method: "POST",
		headers: {
			Authorization: "required", // Must be present or AppSync will auto-return 401
			"Content-Type": "Content-Type:application/graphql",
			Accept: "application/json",
		},
		body: JSON.stringify({
			query: print(document),
			variables,
		}),
	});

	const { data, errors } = (await response.json()) as ExecutionResult<Record<typeof queryName, Response>>;
	if (errors) {
		throw new Error(`GraphQL request failed with errors: ${JSON.stringify(errors)}`);
	}
	if (!data) {
		throw new Error(`GraphQL response has no data`);
	}

	const responseData = data[queryName];
	if (responseData === undefined) {
		throw new Error(`GraphQL response data doesn't contain ${queryName}`);
	}
	return responseData;
}

export const query = request;
export const mutation = request;

export async function queryAll<Item, Variables extends Record<string, unknown> = Empty>(
	endpoint: string,
	document: DocumentNode,
	variables?: Variables,
): Promise<Item[]> {
	const queryVariables = (variables ?? {}) as Variables & { nextToken?: string };
	const result: Item[] = [];

	do {
		const { items, nextToken } = await query<ListResponse<Item>, typeof queryVariables>(
			endpoint,
			document,
			queryVariables,
		);

		result.push(...items);
		queryVariables.nextToken = nextToken;
	} while (queryVariables.nextToken);

	return result;
}
