<script lang="ts">
	import { getTranslate } from "../utils/getTranslate";
	import translations from "./Address.translations.json";
	import Link from "./Link.svelte";
	import { nbspify } from "../../../core/utils/nbspify.js";

	const translate = getTranslate(translations);

	let addressClass = "";
	export { addressClass as class };
</script>

<Link
	class="group text-white decoration-transparent hover:decoration-white {addressClass}"
	href="https://maps.app.goo.gl/dcgbMEPw8GXfbU8f6"
	target="_blank"
	rel="noopener noreferrer"
>
	<table>
		<tr>
			<td class="whitespace-nowrap font-semibold">{nbspify("VertiFlex s. r. o.")}</td>
		</tr>
		<tr>
			<td class="whitespace-nowrap">{nbspify("Gen. Sochora 6176/6A")}</td>
		</tr>
		<tr>
			<td class="whitespace-nowrap">{nbspify("708 00 Ostrava")}</td>
		</tr>
		<tr>
			<td>{translate("czechRepublic")}</td>
		</tr>
	</table>
</Link>
