<script lang="ts">
	import Icon from "./Icon.svelte";
	import success from "../../../core/assets/icons/check.svg?raw";
	import error from "../../../core/assets/icons/error.svg?raw";
	import info from "../../../core/assets/icons/info.svg?raw";
	import warning from "../../../core/assets/icons/warning.svg?raw";

	type MessageVariant = "success" | "error" | "info" | "warning";

	let messageClass = "";
	export { messageClass as class };
	export let variant: MessageVariant = "error";

	const messageVariants: Record<MessageVariant, string> = {
		success: "bg-tertiary-50",
		error: "bg-primary-100 border-primary text-primary",
		info: "bg-primary-100",
		warning: "bg-orange-100",
	};

	const messageIconVariants: Record<MessageVariant, string> = {
		success: "text-tertiary h-[.8rem] [&>svg]:mt-[.1rem]",
		error: "text-primary h-4 [&>svg]:mt-[.15rem]",
		info: "text-secondary-300 h-4 [&>svg]:mt-[.15rem]",
		warning: "text-orange-400 h-[.8rem] [&>svg]:mt-[.1rem]",
	};

	const iconMap: Record<MessageVariant, string> = {
		success,
		error,
		info,
		warning,
	};
</script>

<div
	class="mt-1 flex items-baseline gap-2 text-wrap rounded-lg p-4 text-xs font-semibold leading-[1.1] text-black {messageVariants[
		variant
	]} {messageClass}"
>
	<Icon icon={iconMap[variant]} class={messageIconVariants[variant]} />
	<slot />
</div>
