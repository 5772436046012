import { generateButtonClasses } from "./ButtonClasses.js";
import type { LinkProps, LinkVariant } from "./LinkProps.js";

export function generateLinkClasses({
	disabled,
	variant,
	asButton,
	linkVariant,
	noUnderline,
	linkClass,
}: LinkProps): string {
	if (asButton) {
		return generateButtonClasses({ disabled, variant });
	}

	const linkVariants: Record<LinkVariant, string> = {
		secondary: "text-secondary decoration-secondary",
		primary: "text-primary decoration-primary",
	};
	const variantClass = linkVariants[linkVariant ?? "primary"];
	const disabledClass = disabled && "!text-neutral-400 pointer-events-none";

	return [
		"transition-[text-decoration,color] duration-300 cursor-pointer hover:decoration-transparent group flex gap-4 items-center",
		linkClass,
		variantClass,
		noUnderline ? "" : "underline",
		disabledClass,
	]
		.filter(Boolean)
		.join(" ");
}
