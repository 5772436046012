import type { EnumObject } from "./EnumObject.js";
import type { EnumType } from "./EnumType.js";
import { keys } from "./keys.js";

/**
 * Get a list of enum values.
 */
export function values<Enum extends EnumType>(enumObj: EnumObject<Enum>): Enum[] {
	const maybeValues = Object.values(enumObj);
	const isNumberBased = maybeValues.length !== keys(enumObj).length;
	return isNumberBased ? maybeValues.filter((key) => typeof key === "number") : maybeValues;
}
